import React, {useEffect, useState} from "react";
import axios from "axios";
import {Helmet} from "react-helmet";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name === "" || email === "" || subject === "" || message === "") {
      alert("All fields are required!");
      return;
    }

    const to_contact = {
      name,
      email,
      subject,
      message,
    };

    axios
      .post(`${process.env.REACT_APP_API}/contacts`, to_contact)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    alert("Thanks for Contacting us! We Will get in touch with you ASAP...");

    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>SilverCross | Trust Begins Here</title>
        <meta name="title" content="Silvercross | Trust Begins Here" />
        <meta
          name="description"
          content="Welcome to SilverCross - Where Pharma Meets Tech 1st Pharma Brand in India to launch QR based Batch Certificate
                   Discover Quality and Trust in Every Dose."
        />
        <link rel="canonical" href="https://www.silvercross.in/contact" />
      </Helmet>
      <section className="ls section_padding_top_50 section_padding_bottom_75">
        <div className="container">
          <div className="row">
            <div className="col-sm-12" style={{textAlign: "center"}}>
              <h2 className="logo-color1">Contact Us</h2>
            </div>
          </div>
          <div className="row topmargin_40 why">
            <div style={{paddingBottom: "30px"}} className="col-sm-6 to_animate" data-animation="pullDown">
              <div className=" text-center">
                <h4 className="">Customer Support</h4>
                <p>For business inquiries, reach out to our dedicated customer support team </p>
                <div>
                  <a href="mailto:support@silvercross.in">
                    <i className="rt-icon2-mail"></i>
                  </a>
                  &nbsp; &nbsp; &nbsp;
                  <span>
                    <a className=" highlight2links" href="mailto:support@silvercross.in">
                      support@silvercross.in
                    </a>
                  </span>
                </div>
                <br />
                <div>
                  <a style={{marginLeft: "-40px"}} href="tel:+918762068888">
                    <LocalPhoneIcon style={{color: "black"}} />
                  </a>
                  &nbsp; &nbsp; &nbsp;
                  <span>
                    <a className=" highlight2links" href="tel:+918762068888">
                      +91-8762068888
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div style={{paddingBottom: "30px"}} className="col-sm-6 to_animate" data-animation="pullDown">
              <div className="teaser text-center">
                <h4 className="">Location</h4>

                {/* <div className="teaser_icon highlight2 size_normal"> <i className="rt-icon2-location2"></i>{" "}</div> */}
                <p>
                  Head Office: 41-3-79/2, Mysore Rd, near Manoj Cargo Carriers, New Timberyard Layout, Bengaluru, Karnataka 560026 <br></br> <strong>Office Hours :</strong> Monday - Saturday, 9:00 AM - 6:00 PM IST
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
