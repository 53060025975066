import React, {useState, useEffect, useContext} from "react";
import {Link, useLocation, useHistory} from "react-router-dom";
import silvercrossLogo from "../../assets/svg/silvercross.svg";
import {productCategories} from "../App";

export default function Navbar() {
  // const mediaMatch = window.matchMedia("(min-width: 990px)");
  const [matches, setMatches] = useState();
  const [isHovered, setHovered] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const {pathname} = location;
  const splitLocation = pathname.split("/");
  const {categories} = useContext(productCategories);

  useEffect(() => {
    // const handler = (e) => setMatches(e.matches);
    // mediaMatch.addListener(handler);
    // return () => mediaMatch.removeListener(handler);
  }, []);

  return (
    <div>
      <section className="page_topline cs logo-color table_section table_section_sm section_padding_top_5 section_padding_bottom_5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-8 text-center text-sm-left hide-it">
              <div className="padding-left--75">
                {/* <i className="fa fa-clock-o rightpadding_5" aria-hidden="true"></i> */}
                TRUST BEGINS HERE
              </div>
            </div>
            <div className="col-sm-4 text-center text-sm-right greylinks padding-right--75 padding-left--0">
              {" "}
              <span className="rightpadding_10">Follow Us:</span>
              <a target="_blank" rel="noopener noreferrer" className="soc-icon color-icon soc-facebook" href="https://www.facebook.com/p/SilverCross-Official-61550827695033/" title="Facebook">
                fb
              </a>
              {/* <a target="_blank" rel="noopener noreferrer" className="soc-icon color-icon soc-twitter" href="#" title="Twitter"></a> */}
              {/* <a target="_blank" rel="noopener noreferrer" className="soc-icon color-icon soc-google" href="#" title="Google"></a> */}
              <a target="_blank" rel="noopener noreferrer" className="soc-icon color-icon soc-instagram" href="https://www.instagram.com/silvercross.in/" title="Instagram">
                instagram
              </a>
              <a target="_blank" rel="noopener noreferrer" className="soc-icon color-icon soc-linkedin" href="https://www.linkedin.com/company/silvercross-medi-sciences/posts/?feedView=all" title="Linkedin">
                linkedin
              </a>
              {/* <a target="_blank" rel="noopener noreferrer" className="soc-icon color-icon soc-youtube" href="#" title="Youtube"></a> */}
            </div>
          </div>
        </div>
      </section>
      <header className="page_header header_white toggler_xs_right section_padding_25">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 display_table">
              <div className="header_left_logo display_table_cell">
                {" "}
                <div onClick={() => history.push("/")} className="logo top_logo" style={{cursor: "pointer"}}>
                  <img className="carin_logo" src={silvercrossLogo} alt="" />
                </div>
              </div>
              <div className="header_mainmenu display_table_cell text-center">
                <nav className="mainmenu_wrapper">
                  <ul className="mainmenu nav sf-menu">
                    <li className={splitLocation[1] === "" ? "active" : ""}>
                      {" "}
                      {matches ? (
                        <Link className="sf-with-ul" to="/">
                          Home
                        </Link>
                      ) : (
                        <Link className="sf-with-ul" to="/">
                          Home
                        </Link>
                      )}
                    </li>
                    <li className={splitLocation[1] === "about" ? "active" : ""}> {matches ? <Link to="/about">About Us</Link> : <Link to="/about">About Us</Link>}</li>
                    <li onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className={splitLocation[1] === "offerings" ? (isHovered ? "active sfHover" : "active") : isHovered ? "sfHover" : ""}>
                      {" "}
                      {matches ? (
                        <Link className="sf-with-ul" to="/offerings">
                          Our Offerings
                        </Link>
                      ) : (
                        <Link className="sf-with-ul" to="/offerings">
                          Our Offerings
                        </Link>
                      )}
                      <ul style={isHovered ? {display: "block", marginTop: 0} : {display: "none", marginTop: "5px"}}>
                        {categories.map((data) => {
                          return (
                            <li>
                              <Link to={`/offerings/${data.category}?id=${data.id}`}>{data.category}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                    <li className={splitLocation[1] === "community" ? "active" : ""}> {matches ? <Link to="/community">Our Reach</Link> : <Link to="/community">Our Reach</Link>}</li>
                    {/* <li className={splitLocation[1] === "blogs" ? "active" : ""}> {matches ? <Link to="/blogs">Our Blogs</Link> : <Link to="/blogs">Our Blogs</Link>} </li> */}
                    <li className={splitLocation[1] === "careers" ? "active" : ""}> {matches ? <Link to="/careers">Careers</Link> : <Link to="/careers">Careers</Link>}</li>
                    <li className={splitLocation[1] === "contact" ? "active" : ""}>{matches ? <Link to="/contact">Contact</Link> : <Link to="/contact">Contact</Link>}</li>
                  </ul>
                </nav>
                <span className="toggle_menu">
                  <span></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
