import React from "react";
import {Link} from "react-scroll";

export default function OfferingsCarousel(props) {
  return (
    <div className="gallery-item rounded caro-item">
      <div className="item-media rounded overflow_hidden">
        <img style={{width: "80%"}} src={`${process.env.REACT_APP_PRODUCTS_URL}/${props.data.name.trim()}.jpg`} alt="product" />
        <div className="media-links">
          {" "}
          <Link to={props.data.name} offset={-100} spy={true} smooth={true} className="abs-link prettyPhoto"></Link>{" "}
        </div>
        <p className="offering-titles">{props.data.brand_name}</p>
      </div>
    </div>
  );
}
