import React, {useEffect, useState} from "react";
import OfferingsCarousel from "./OfferingsCarousel";
import OfferingsDisplay from "./OfferingsDisplay";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

export default function Offerings() {
  const responsive = {
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 5,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 3,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
    },
  };
  const location = useLocation();

  const [offerings, setOfferings] = useState([]);
  const [category, setCategory] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    const category = location.pathname && location.pathname.split("/").pop();
    const id = location.search.split("=").pop();
    setCategory(category);
    const fetchOfferings = async () => {
      try {
        setOfferings(offerings);
        const response = await axios.get(`${process.env.REACT_APP_SILVERCROSS_API}/products/category?id=${id}`);
        setOfferings(response.data);
      } catch (e) {
        console.log(e);
        setOfferings(offerings);
      }
    };

    fetchOfferings();
  }, []);

  return (
    <div>
      <Helmet>
        <title>SilverCross | Trust Begins Here</title>
        <meta name="title" content="Silvercross | Trust Begins Here" />
        <meta
          name="description"
          content="Welcome to SilverCross - Where Pharma Meets Tech 1st Pharma Brand in India to launch QR based Batch Certificate
                   Discover Quality and Trust in Every Dose."
        />
        <link rel="canonical" href={`https://www.silvercross.in/offerings/${category}`} />
      </Helmet>
      <section className="ls section_padding_top_25">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {" "}
              <img src={process.env.REACT_APP_ASSETS_URL + `/${category}.jpeg`} alt="" className="alignleft offering_img" />
              <div className="to_animate section_padding_top_100" data-animation="fadeInLeft" data-delay="150">
                <h3 className="section_header">{category}</h3>
              </div>
            </div>
            <div className="col-sm-12 to_animate" data-animation="fadeInUp" data-delay="150">
              <h3 className="logo-color1 product_header">Products of SilverCross:</h3>
            </div>
          </div>
        </div>
      </section>

      <section id="products" className="offerings ds color parallax page_gallery columns_padding_25 to_animate" data-animation="fadeInLeft" data-delay="150">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Carousel showDots={false} responsive={responsive} transitionDuration={500} containerClass="carousel-container" itemClass="carousel-item-padding-40-px">
                {offerings.map((offering) => (
                  <OfferingsCarousel data={offering} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>

      <section className="ls section_padding_bottom_65">
        <div className="container">
          <div className="row">
            {offerings.length &&
              offerings.map((offering, index) => {
                return <OfferingsDisplay offering="infectives" data={offering} idx={index + 1} />;
              })}
          </div>
        </div>
      </section>
    </div>
  );
}
