import React from "react";
import Pills from "../../assets/svg/pills.svg";
import Injection from "../../assets/svg/injection-syringe.svg";
import Doc from "../../assets/svg/law-document.svg";
import Certificate from "../../assets/svg/certificate-alt-1.svg";
import Support from "../../assets/svg/support-faq.svg";
import Medicine from "../../assets/svg/medicine.svg";
import Tabletes from "../../assets/svg/tablets.svg";

export default function HexagonalDesign({handlePopUp, product, openBatchModal, selectedBatchFile}) {
  return (
    <div className="hex-cntr">
      <section className="container hexagonal-page">
        <div className="first-hex">
          <div className="hexagonal-shp hex bg-color2" onClick={(e) => handlePopUp(e, "Mode of Action")}>
            <div className="inside-hex hex">
              <div className="sub-hex hex">
                <div className="data-hex hex">
                  <div>
                    <div className="hex-icon bg-color2">
                      <img src={Pills} className="hexagonIcon" />
                    </div>

                    <p className="hexaColor2">Mode of Action</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="top-hex">
          <div className="hexagonal-shp hex bg-color1" onClick={(e) => handlePopUp(e, "Side Effects")}>
            <div className="inside-hex hex">
              <div className="sub-hex hex">
                <div className="data-hex hex">
                  <div>
                    <div className="hex-icon bg-color1">
                      <img src={Injection} className="hexagonIcon" />
                    </div>

                    <p className="hexaColor1">Side Effects</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mid-hex">
          <div className="hexagonal-shp hex bg-color2 hex-mid-resp1" onClick={(e) => handlePopUp(e, "How to use?")}>
            <div className="inside-hex hex">
              <div className="sub-hex hex">
                <div className="data-hex hex">
                  <div>
                    <div className="hex-icon bg-color2">
                      <img src={Doc} className="hexagonIcon" />
                    </div>

                    <p className="hexaColor2">How to use?</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hexagonal-shp hex bg-color1">
            <div className="inside-hex hex">
              <div className="sub-hex hex">
                <div className="data-hex hex">
                  <div>
                    <p className="hexaColor1" style={{fontSize: window.innerWidth < 767 ? "9px" : "1rem"}}>
                      {product.brand_name.toUpperCase()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hexagonal-shp hex bg-color1" onClick={() => openBatchModal(selectedBatchFile)}>
            <div className="inside-hex hex">
              <div className="sub-hex hex">
                <div className="data-hex hex">
                  <div>
                    <div className="hex-icon bg-color1">
                      <img src={Certificate} className="hexagonIcon" />
                    </div>

                    <p className="hexaColor1">Batch Certificate</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hexagonal-shp hex bg-color2 hex-mid-resp2" onClick={(e) => handlePopUp(e, "FAQs")}>
            <div className="inside-hex hex">
              <div className="sub-hex hex">
                <div className="data-hex hex">
                  <div>
                    <div className="hex-icon bg-color2">
                      <img src={Support} className="hexagonIcon" />
                    </div>

                    <p className="hexaColor2">FAQs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-hex">
          <div className="hexagonal-shp hex bg-color1" onClick={(e) => handlePopUp(e, "Indications")}>
            <div className="inside-hex hex">
              <div className="sub-hex hex">
                <div className="data-hex hex">
                  <div>
                    <div className="hex-icon bg-color1">
                      <img src={Medicine} className="hexagonIcon" />
                    </div>

                    <p className="hexaColor1">Indications</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="last-hex">
          <div className="hexagonal-shp hex bg-color2" onClick={(e) => handlePopUp(e, "Drug Interaction")}>
            <div className="inside-hex hex">
              <div className="sub-hex hex">
                <div className="data-hex hex">
                  <div>
                    <div className="hex-icon bg-color2">
                      <img src={Tabletes} className="hexagonIcon" />
                    </div>

                    <p className="hexaColor2">Drug Interaction</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
