import React, {useEffect} from "react";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Paper} from "@material-ui/core";
import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";

export default function Community() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>SilverCross | Trust Begins Here</title>
        <meta name="title" content="Silvercross | Trust Begins Here" />
        <meta
          name="description"
          content="Welcome to SilverCross - Where Pharma Meets Tech 1st Pharma Brand in India to launch QR based Batch Certificate
                   Discover Quality and Trust in Every Dose."
        />
        <link rel="canonical" href="https://www.silvercross.in/community" />
      </Helmet>
      <section className="ls section_padding_bottom_50 columns_padding_25">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="starting text-center">
                <Carousel showArrows={true} autoPlay autoFocus dynamicHeight infiniteLoop interval={"4000"}>
                  <Paper>
                    <img className="d-block w-100" src={process.env.REACT_APP_ASSETS_URL + "/homeBanner3.jpg"} alt="First slide" />
                  </Paper>
                </Carousel>
                {/* <div className="flexslider text-center" data-nav="false">
									<ul className="slides">
										<li> <img src="https://d214gcurai71bm.cloudfront.net/carin-images/stock-images/Our_Community.jpg" alt="" /> </li>
										<li> <img src="https://d214gcurai71bm.cloudfront.net/carin-images/stock-images/Our_Community_II.jpg" alt="" /> </li>
									</ul>
								</div> */}
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12">
              <article>
                <div style={{padding: "20px"}} className="col-sm-12 to_animate bg-color-abt" data-animation="fadeInLeft" data-delay="150">
                  <h3 className=" logo-color1">SilverCross Partners </h3>
                  <div className="entry-excerpt">
                    <p style={{fontSize: "22px"}}>Join our network of trusted partners and help bring quality healthcare to all. (Diwali video)</p>
                  </div>
                  <br />
                </div>
              </article>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12">
              <article>
                <div style={{padding: "20px"}} className="col-sm-12 to_animate bg-color-abt" data-animation="fadeInLeft" data-delay="150">
                  <h3 className=" logo-color1">Events </h3>
                  <div className="entry-excerpt">
                    <strong>Upcoming Events:</strong>
                    <p style={{fontSize: "22px"}}>Join us in person or online for product launches, health awareness workshops, and educational seminars. Upcoming: “SilverCross Pharmacist Meet - 2024,” “National Health Webinar,” and “Seasonal Immunity Drive.”</p>
                  </div>
                  <br />
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
