import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import TeamPhotos from "./team.json";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 5,
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 4,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
  },
};
export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>SilverCross | Trust Begins Here</title>
        <meta name="title" content="Silvercross | Trust Begins Here" />
        <meta
          name="description"
          content="Welcome to SilverCross - Where Pharma Meets Tech 1st Pharma Brand in India to launch QR based Batch Certificate
                   Discover Quality and Trust in Every Dose."
        />
        <link rel="canonical" href="https://www.silvercross.in/about" />
      </Helmet>
      <section className="page_breadcrumbs ds color parallax section_padding_bottom_75">
        <div className="starting">
          <img src={process.env.REACT_APP_ASSETS_URL + "/aboutUs.jpg"} alt="" />
        </div>
      </section>
      <section className=" ls about_section section_padding_top_40 section_padding_bottom_40">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="row about_us">
                {/* <div className="col-sm-12 to_animate bg-color-abt" style={{padding: "20px"}} data-animation="fadeInLeft" data-delay="150">
                  <h3 className="logo-color1">About Carin</h3>
                  <p>We at CARIN are advocators of Science and using it to advance health is our calling.</p>
                  <p>We are an assistive, Digital-First Healthcare brand. We believe that technology can be a great leveler and make Quality Healthcare much more accessible. We strive for a better future, for all.</p>
                </div> */}
                <div className="col-sm-12 to_animate bg-color-abt" style={{padding: "20px"}} data-animation="fadeInRight" data-delay="150">
                  <h3 className="logo-color1">Our Mission</h3>
                  <p>Building Trust in Health Through Transparency.</p>
                  <p>SilverCross is committed to providing high-quality, verifiable medicines to every Indian.</p>
                  <p>Our mission is to make affordable healthcare accessible while ensuring safety, transparency, and educational resources.</p>
                </div>
                <div className="col-sm-12 to_animate bg-color-abt" style={{padding: "20px"}} data-animation="fadeInRight" data-delay="150">
                  <h3 className="logo-color1">Our Journey</h3>
                  <p>SilverCross began with the vision of redefining healthcare by combining pharmacological expertise, transparency, and modern technology. Our network spans over 50,000+ active pharmacies, aiming to reach every corner of India with quality medicines. Join us on our journey toward a healthier, fitter India.</p>
                </div>
                <div className="col-sm-12 to_animate bg-color-abt" style={{padding: "20px"}} data-animation="fadeInLeft" data-delay="150">
                  <h3 className="logo-color1">Why SilverCross ?</h3>
                  <strong className="inline">1st Brand in India to launch the real P.T.R. </strong> <br />
                  <b className="inline">● Quality Assurance : </b>
                  <p style={{marginLeft: "2%"}}>SilverCross medicines exceed industry standards by meeting 100% dissolution versus the usual 85%. Our quality and safety protocols ensure that every medicine you use is reliable and verified.</p>
                  <br />
                  <b className="inline">● Batch Verification </b>
                  <p style={{marginLeft: "2%"}}>Every SilverCross product has a unique QR code that allows customers to instantly verify the product's quality and authenticity. We are the first in India to offer this level of transparency, helping consumers trust every dose.</p>
                  <br />
                  <b className="inline">● Customer Trust </b>
                  <p style={{marginLeft: "2%"}}>We value your trust. SilverCross products are backed by quality and backed by education, ensuring that our customers know exactly what they’re consuming and how it benefits their health.</p>
                </div>
                <div className="col-sm-12 to_animate bg-color-abt" style={{padding: "20px"}} data-animation="fadeInLeft" data-delay="150">
                  <h3 className="logo-color1">Values & Commitments</h3>
                  <strong className="inline">● Pharmacology </strong>
                  <br />
                  <strong className="inline">● Transparency </strong>
                  <br />
                  <strong className="inline">● Rewards </strong>
                </div>
                <div className="col-sm-12" style={{padding: "20px"}} data-animation="fadeInRight" data-delay="150">
                  <h3 className="logo-color1" style={{textAlign: "center"}}>
                    Our Team
                  </h3>
                  <div className="row topmargin_30">
                    <Carousel itemClass="carousel-item-padding-40-px" responsive={responsive} keyBoardControl={true}>
                      {TeamPhotos.team.map((data) => {
                        return (
                          <div className="gallery-item rounded" style={{margin: "10px 10%"}}>
                            <div className="item-media rounded overflow_hidden">
                              <div style={{height: "70%", width: "70%", borderRadius: "50%", overflow: "hidden", margin: "0px auto"}}>
                                <img src={`${process.env.REACT_APP_SC_BASE_URL}/team/${data.name}.jpg`} alt={data} />
                              </div>
                              <div>
                                <br />
                                <strong>{data.name}</strong>
                                <div>{data.designation}</div>
                                {data.description.length && (
                                  <p style={{fontSize: "14px"}}>
                                    {"("}
                                    {data.description}
                                    {")"}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
                <div className="col-sm-12" style={{padding: "20px"}} data-animation="fadeInRight" data-delay="150">
                  <h3 className="logo-color1" style={{textAlign: "center"}}>
                    Our State Leaders
                  </h3>
                  <div className="row topmargin_30">
                    <Carousel itemClass="carousel-item-padding-40-px" responsive={responsive} keyBoardControl={true}>
                      {TeamPhotos.stateLeaders.map((data) => {
                        return (
                          <div className="gallery-item rounded" style={{margin: "10px 10%"}}>
                            <div className="item-media rounded overflow_hidden">
                              <div style={{height: "70%", width: "70%", borderRadius: "50%", overflow: "hidden", margin: "0px auto"}}>
                                <img src={`${process.env.REACT_APP_SC_BASE_URL}/team/${data.name}.jpg`} alt={data} />
                              </div>
                              <div>
                                <br />
                                <strong>{data.name}</strong>

                                <div>{data.designation}</div>
                                {data.description.length ? (
                                  <p style={{fontSize: "14px"}}>
                                    {"("}
                                    {data.description}
                                    {")"}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
