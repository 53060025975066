import React, {useEffect, useState} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from "react-responsive-carousel";
import {Paper} from "@material-ui/core";
import LazyLoad from "react-lazyload";
import axios from "axios";
import {Helmet} from "react-helmet";

export default function Careers() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState("");
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");
  const [openings, setOpenings] = useState([]);
  const [resume, setResume] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "" || email === "" || roles === "" || message === "" || number === "" || !resume) {
      alert("All fields are required!");
      return;
    }
    const urlPath = `${process.env.REACT_APP_EAGLE_API}/wholesale/uploadPhotosOne`;
    const formData = new FormData();
    formData.append("file", resume); // Assuming `file` is the uploaded file object

    axios
      .post(urlPath, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const resumeLink = response.data.data[0];
        const careers = {
          name,
          email,
          number,
          roles,
          message,
          resume: resumeLink,
        };
        console.log({careers, resumeLink});

        axios
          .post(`${process.env.REACT_APP_SILVERCROSS_API}/career/add`, careers)
          .then((res) => {
            alert("Thanks for showing interest in us! We Will get in touch with you ASAP...");
          })
          .catch((err) => {
            console.log(err);
            window.alert("Error while submitting application, Please try again later");
          });

        setName("");
        setEmail("");
        setRoles("");
        setMessage("");
        setNumber("");
        setResume();
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        window.alert("Error while submitting application, Please try again later");
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchRoles = async () => {
      try {
        setOpenings(openings);
        const response = await axios.get(`${process.env.REACT_APP_API}/roles`);
        // console.log(response.data);
        setOpenings(response.data);
      } catch (e) {
        console.log(e);
        setOpenings(openings);
      }
    };

    fetchRoles();
  }, []);

  return (
    <div>
      <Helmet>
        <title>SilverCross | Trust Begins Here</title>
        <meta name="title" content="Silvercross | Trust Begins Here" />
        <meta
          name="description"
          content="Welcome to SilverCross - Where Pharma Meets Tech 1st Pharma Brand in India to launch QR based Batch Certificate
                   Discover Quality and Trust in Every Dose."
        />
        <link rel="canonical" href="https://www.silvercross.in/careers" />
      </Helmet>
      <section className="ls columns_padding_25">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2 className="logo-color1 txt-bold text-center">Life at SilverCross</h2>
              <div className="starting blog_slider">
                <Carousel showArrows={true} autoPlay autoFocus dynamicHeight infiniteLoop interval={"4000"}>
                  <Paper>
                    <img src={process.env.REACT_APP_IMAGES + "/careers_1.jpg"} alt="First slide" />
                  </Paper>
                  <Paper>
                    <LazyLoad>
                      <img src={process.env.REACT_APP_IMAGES + "/careers_2.jpg"} alt="Second slide" />
                    </LazyLoad>
                  </Paper>
                  <Paper>
                    <LazyLoad>
                      <img src={process.env.REACT_APP_IMAGES + "/careers_3.jpg"} alt="Third slide" />
                    </LazyLoad>
                  </Paper>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />

      <section className="ls columns_padding_25">
        <div className="container">
          <div style={{padding: "0 20px"}} className="row">
            <div style={{paddingBottom: "30px"}} className="col-sm-12 to_animate bg-color-abt" data-animation="fadeInUp" data-delay="150">
              <h3 className=" logo-color1">Why Join SilverCross? </h3>
              <p style={{fontSize: "20px"}}>At SilverCross, we value integrity, passion, and innovation. With a mission to make healthcare more transparent and accessible, we offer our employees meaningful careers in a collaborative environment.</p>
              <p style={{fontSize: "20px"}}>Work with SilverCross and be part of India’s healthcare transformation. We seek passionate individuals in fields like R&D, Quality Assurance, Sales, and Marketing.</p>
              <br />
              <h4 className=" logo-color1">SilverCross Workspace</h4>
              <p>
                We aim to create a positive workspace with a dynamic workforce and <span className="txt-bold">open culture.</span>
              </p>
              <p>
                We embrace <span className="txt-bold">diversity</span> at all levels in the organization and value the different perspectives that come with it. We provide safe, secure space and professional freedom to all our employees, to aspire and achieve. We seek to nurture a competitive talent pool bound by a shared value system.
              </p>
              <p>
                We embody an <span className="txt-bold">entrepreneurial culture</span> and consistently push ourselves to take risks and think creatively. We at SilverCross promise an exciting journey. We do something new, every day.
              </p>

              <p>
                All our employees are fiercely <span className="txt-bold">passionate</span> and their knowledge, skills and ability to innovate are the reasons for our success. We invest in their continuous learning and personal development and empower them to realize their true potential.
              </p>
              <span className="txt-bold">We support and care for all our employees.</span>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section className="ls columns_padding_25 section_padding_top_50 section_padding_bottom_100">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 to_animate" data-animation="scaleAppear" data-delay="200">
              <h3 className="logo-color1">Want to join our team:</h3>
              <form className="contact-form row columns_padding_10" name="careers" id="myForm">
                <div className="col-sm-6">
                  <div className="contact-form-name">
                    {" "}
                    <label for="name">
                      Full Name <span className="required">*</span>
                    </label>{" "}
                    <input onChange={(e) => setName(e.target.value)} type="text" aria-required="true" size="30" value={name} name="name" id="name" className="form-control" placeholder="Full Name" />{" "}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="contact-form-email">
                    {" "}
                    <label for="email">
                      Email address<span className="required">*</span>
                    </label>{" "}
                    <input onChange={(e) => setEmail(e.target.value)} type="email" aria-required="true" size="30" value={email} name="email" id="email" className="form-control" placeholder="Email Address" />{" "}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="contact-form-phone">
                    {" "}
                    <label for="phone">Phone Number</label> <input onChange={(e) => setNumber(e.target.value)} type="text" size="30" value={number} name="phone" id="phone" className="form-control" placeholder="Phone Number" />{" "}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="contact-form-subject">
                    {" "}
                    <label for="role">
                      Role <span className="required">*</span>
                    </label>{" "}
                    <input onChange={(e) => setRoles(e.target.value)} type="text" aria-required="true" value={roles} name="role" id="role" className="form-control" placeholder="Enter Role" />{" "}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="contact-form-subject">
                    {" "}
                    Upload Resume
                    <input accept=".doc,.pdf,.docx" onChange={(e) => setResume(e.target.files[0])} type="file" aria-required="true" name="resume" id="resume" className="form-control" placeholder="Upload Resume" />{" "}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="contact-form-message">
                    {" "}
                    <label for="message">Message</label> <textarea onChange={(e) => setMessage(e.target.value)} aria-required="true" rows="5" cols="45" name="message" value={message} id="message" className="form-control" placeholder="Your Message"></textarea>{" "}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="contact-form-submit topmargin_10">
                    {" "}
                    <button onClick={handleSubmit} type="submit" id="contact_form_submit" name="contact_submit" className="theme_button color2 inverse min_width_button">
                      Send Message
                    </button>{" "}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
