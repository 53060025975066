import React, {useEffect, useContext} from "react";
import {Helmet} from "react-helmet";
// import { Link } from "react-router-dom";
import {productCategories} from "../App";

export default function Offerings() {
  const {categories} = useContext(productCategories);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>SilverCross | Trust Begins Here</title>
        <meta name="title" content="Silvercross | Trust Begins Here" />
        <meta
          name="description"
          content="Welcome to SilverCross - Where Pharma Meets Tech 1st Pharma Brand in India to launch QR based Batch Certificate
                   Discover Quality and Trust in Every Dose."
        />
        <link rel="canonical" href="https://www.silvercross.in/offerings" />
      </Helmet>
      <section className="page_breadcrumbs ds color parallax section_padding_top_50 section_padding_bottom_20">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="logo-color1">OUR OFFERINGS</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="ls section_padding_top_20 section_padding_bottom_100">
        <div className="container">
          <div className="isotope_container isotope row masonry-layout columns_margin_bottom_20">
            {categories.map((data) => {
              return (
                <div className="isotope-item col-md-3 col-sm-6">
                  <article  className="vertical-item content-padding with_border rounded text-center offeringCard">
                    <div className="item-media top_rounded overflow_hidden">
                      {" "}
                      <img src={`${process.env.REACT_APP_ASSETS_URL}/${data.category}.jpeg`} alt={data.category} />{" "}
                    </div>
                    <div className="item-content">
                      <h4 className="logo-color1 offeringCardTitle">
                        {" "}
                        <a href={`/offerings/${data.category}?id=${data.id}`}>{data.category}</a>{" "}
                      </h4>
                      <p className="topmargin_20">
                        {" "}
                        <a href={`/offerings/${data.category}?id=${data.id}`} className="theme_button color2 inverse min_width_button">
                          Read More
                        </a>{" "}
                      </p>
                    </div>
                  </article>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}
